import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Seo from "../../components/Seo";
import {Link} from "gatsby";

import banner from "../../images/feature-housepartner.png";
import homeowner from "../../images/housepartner/homeowner.png";
import inspector from "../../images/housepartner/inspector.png";
import home from "../../images/housepartner/dash.png";
import errorpage from "../../images/housepartner/404.png";
import login from "../../images/housepartner/first-time.png";
import details from "../../images/housepartner/details.png";
import account from "../../images/housepartner/account.png";
import appliances from "../../images/housepartner/Appliances.png";

function FadeInSection(props) {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef();
    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting));
        });
        observer.observe(domRef.current);
        return () => observer.unobserve(domRef.current);
    }, []);
    return (
            <div
                    className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
                    ref={domRef}
            >
                {props.children}
            </div>
    );
}

class HousePartner extends React.Component {

    render() {
        return (
                <Layout pageName="housepartner">
                    <Seo
                            title={'HousePartner'}
                    />
                    <Container>
                        <section>
                            <div className="extra-large-wrapper mt-16 sm:mt-36">
                                <h1 className="font-serif text-left text-4xl sm:text-9xl leading-none sm:-mt-4">HousePartner</h1>
                                <div className="sm:flex justify-between mt-16 sm:mt-24">
                                    <div className="flex">
                                        <div className="">
                                            <h3 className="font-mono italic text-sm featured-color">Role</h3>
                                            <ul className="w-auto mt-2 inline-flex flex-col items-start">
                                                <li className="border rounded-full py-1 px-4 w-auto font-mono italic text-sm">Brand Identity</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Website & App Design</li>
                                                <li className="border rounded-full py-1 px-4 mt-3 font-mono italic text-sm">Frontend Development</li>
                                            </ul>
                                        </div>
                                        <div className="ml-24">
                                            <h3 className="font-mono italic text-sm featured-color">Date</h3>
                                            <h4 className="mt-2">2021</h4>
                                        </div>
                                    </div>
                                    <div className="mt-8 sm:mt-0 sm:w-2/5">
                                        <p className="leading-normal">HousePartner is a startup rethinking how home inspections are presented to buyers. I led the branding efforts, as well as all of the marketing collateral, website and platform design, and frontend development.</p>
                                        <a href="https://housepartnerdev.netlify.app/" target="_blank" rel="noreferrer" className="mt-8 block featured-color hover:underline">Launch Website -></a>
                                    </div>
                                </div>
                                <FadeInSection>
                                <div className="mt-16">
                                    <img src={banner} alt="project images"/>
                                </div>
                                </FadeInSection>
                                <div className="extra-large-wrapper mt-16 sm:mt-32 grid gap-8 lg:gap-16 sm:grid-cols-2 content-start">
                                    <FadeInSection>
                                        <img src={homeowner} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={inspector} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                </div>
                                <div className="extra-large-wrapper mt-16 sm:mt-32 grid gap-8 lg:gap-8 sm:grid-cols-2 content-start">
                                    <FadeInSection>
                                        <img src={login} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={home} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={details} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={appliances} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={account} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                    <FadeInSection>
                                        <img src={errorpage} alt="" className="rounded-2xl border-4 border-lightgrey"/>
                                    </FadeInSection>
                                </div>
                            </div>
                        </section>
                        <section>
                            <FadeInSection>
                                <div className="extra-large-wrapper text-center relative mt-16 sm:mt-32 sm:mb-16">
                                    <Link to="/work/pourigins" className="text-3xl sm:text-6xl font-serif border-b-2 border-white hover:border-black">
                                        next project — Pourigins
                                    </Link>
                                </div>
                            </FadeInSection>
                        </section>
                    </Container>
                </Layout>
        );
    }
}

export default HousePartner;
